<template>
  <div class="login_area">
    <!-- login -->
    <div class="login">
      <!-- header -->
      <div id="header">
        <!-- login_headr -->
        <div class="login_headr">
          <h1 class="login_title_top">
            증강현실로 떠나는 국내여행
            <strong class="big_text">스탬프 팝</strong>
          </h1>
          <p class="login_title_under">관리자 사이트 입니다.</p>
        </div>
        <!-- //login_headr -->
      </div>
      <!-- //header -->
      <!-- login_content -->
      <div class="login_content">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit="onSubmit" @reset="onReset">
            <table>
              <colgroup>
                <col style="width: 100%" />
              </colgroup>
              <tr class="login_box">
                <td>
                  <!-- id_box -->
                  <ValidationProvider
                    ref="validationLoginid"
                    :name="'아이디'"
                    :rules="{
                      required: true,
                    }"
                  >
                    <div class="id_box">
                      <img
                        src="@/assets/img/login_01.png"
                        alt="로그인"
                        style="width: 16px; height: 16px"
                        class="icon_01"
                      />
                      <input
                        type="text"
                        v-model="form.loginid"
                        ref="loginid"
                        autocapitalize="off"
                        class="id_input"
                        placeholder="아이디를 입력해 주세요."
                      />
                    </div>
                  </ValidationProvider>
                  <!-- //id_box -->
                </td>
              </tr>
              <tr>
                <td>
                  <!-- password_box -->
                  <ValidationProvider
                    ref="validationPass"
                    :name="'비밀번호'"
                    :rules="{ required: true }"
                  >
                    <div class="password_box">
                      <img
                        src="@/assets/img/login_02.png"
                        alt="비밀번호"
                        style="width: 16px; height: 16px"
                        class="icon_01"
                      />
                      <input
                        type="password"
                        v-model="form.pass"
                        ref="pass"
                        autocapitalize="off"
                        class="password_input"
                        placeholder="비밀번호를 입력해 주세요."
                      />
                    </div>
                  </ValidationProvider>
                  <!-- //password_box -->
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="form.saveLoginid"
                    id="save_login"
                    class="big_check"
                  />
                  <label for="save_login" class="check_label"
                    >아이디 저장</label
                  >
                </td>
              </tr>
            </table>
            <button
              type="submit"
              :disabled="invalid || waitRequest"
              class="btn_login"
            >
              로그인
            </button>
            <p class="login_text">관리자만 접근이 가능합니다.</p>
          </form>
        </ValidationObserver>
      </div>
      <!-- //login_content -->
      <!-- footer -->
      <div id="footer" class="login_footer">
        <p class="copyright">
          copytight 2020 nextinnovation. All right reserved.
        </p>
      </div>
      <!-- //footer -->
    </div>
    <!-- //login -->
  </div>
</template>

<script>
/**
 * vuex
 */
import { mapGetters } from "vuex";

/**
 * components
 */
// import Alert from "@/components/Alert";

/**
 * services
 */
// import { naverLoginService } from "@/assets/naver.login.service";

export default {
  name: "Login",
  components: {
    /**
     * components
     */
  },
  data() {
    /**
     * data
     */
    return {
      waitRequest: null,

      form: {
        loginid: "",
        pass: "",
        saveLoginid: false,
      },
    };
  },
  created() {
    /**
     * created
     */
    this.$Progress.start();

    if (this.getSaveLoginid) {
      this.form.saveLoginid = true;
      this.form.loginid = this.getSaveLoginid;
    }
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated", "getSaveLoginid", "getUser"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
    async onSubmit(evt) {
      evt.preventDefault();

      const validationLoginid = await this.$refs.validationLoginid.validate();
      if (!validationLoginid.valid) {
        /*
        this.$modal.show(
          Alert,
          {
            alert_name: "알림",
            alert_contnet: validationLoginid.errors[0]
          },
          {
            height: "auto"
          },
          {
            "before-open": () => {},
            "before-close": () => {}
          }
        );
        */

        this.$refs.loginid.focus();
        return false;
      }

      const validationPass = await this.$refs.validationPass.validate();
      if (!validationPass.valid) {
        /*
        this.$modal.show(
          Alert,
          {
            alert_name: "알림",
            alert_contnet: validationPass.errors[0]
          },
          {
            height: "auto"
          },
          {
            "before-open": () => {},
            "before-close": () => {}
          }
        );
        */

        this.$refs.pass.focus();
        return false;
      }

      this.waitRequest = true;

      this.$store
        .dispatch("login", {
          loginid: this.form.loginid,
          pass: this.form.pass,
          saveLoginid: this.form.saveLoginid,
        })
        .then((response) => {
          this.waitRequest = false;

          console.log(response);
          if (response.status == 200) {
            if (this.getUser.role == "ADMIN_VIEWER") {
              this.$router.push({
                name: "StampDashboard",
              });
            } else {
              this.$router.push({
                name: "Dashboard",
              });
            }
          }
        })
        .catch((message) => {
          this.waitRequest = false;
          alert(message);
          /*
          this.$modal.show(
            Alert,
            {
              alert_name: "알림",
              alert_contnet: message
            },
            {
              height: "auto"
            },
            {
              "before-open": () => {},
              "before-close": () => {}
            }
          );
          */
        });
      return true;
    },
    // 취소
    onReset(evt) {
      evt.preventDefault();
      /*
      this.$router.push({
        name: "AuthTerms"
      });
      */
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
